<template>

<com-list api="conditions" :is-fancy="true" :block="block" v-on:loaded="onListLoaded" v-on:response="onResponse">

	<div class="conditions-additional" v-if="symptoms.length">

		<div class="conditions-additional-text"><b>Do you have any additional symptoms?</b> Select below to help narrow down the possible conditions</div>

		<div class="conditions-additional-item" :class="{'is-active': $_.contains(additional, item.id)}" v-on:click="onRelatedClick(item.id)" v-for="(item, index) in symptoms" :key="index">

			<div class="conditions-additional-item-check"></div>
			<div class="conditions-additional-item-name">{{ item.name }}</div>

		</div>

	</div>

	<div class="conditions-item" v-for="(item, index) in filteredList" :key="index">

		<div class="conditions-item-text">

			<div class="conditions-item-text-name">{{ item.name }}</div>
			<div class="conditions-item-text-symptoms" v-if="relatedSymptoms(item.id).length">
				
				<span>Other symptoms</span>

				<span>{{ relatedSymptoms(item.id, true) }}</span>

			</div>

		</div>

		<router-link :to="$link($route.path + '/' + item.url)" class="conditions-item-button">More info</router-link>

	</div>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'
import Vue from 'vue'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	},

	data: function() {
		
		return {
			symptoms: [],
			related: [],
			additional: []
		}

	},	

	computed: {

		filteredList: function() {

			return (this.additional.length) ? this.$_.filter(this.list, function(condition) {

				return this.$_.intersection(this.related[condition.id], this.additional).length

			}.bind(this)) : this.list

		}

	},

	methods: {

		onResponse: function(json) {

			Vue.set(this, 'symptoms', json.symptoms)
			Vue.set(this, 'related', json.related)

		},

		onRelatedClick: function(id) {

			if (this.$_.contains(this.additional, id)) {

				this.additional.splice(this.additional.indexOf(id), 1)

			} else {

				this.additional.push(id)

			}

		},

		relatedSymptoms: function(condition, asString) {

			asString = asString || false

			var list = this.$_.filter(this.symptoms, function(symptom) {

				return this.$_.contains(this.related[condition], symptom.id)

			}.bind(this))

			if (asString) list = this.$_.pluck(list, 'name').join(', ')

			return list

		}

	}

}

</script>

<style scoped>

.conditions-additional {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
	margin-bottom: 50px;
	user-select: none;
}

.conditions-additional-text {
	color: #343434;
	font-size: 16px;
	margin-bottom: 10px;
	line-height: 30px;
	grid-column: 1 / 4;
	
}

.conditions-additional-item {
	display: flex;
	height: 30px;
	align-items: center;
	cursor: pointer;
}

.conditions-additional-item-check {
	width: 22px;
	height: 22px;
	background-color: #f5f5f5;
	flex-shrink: 1;
	border-radius: 50%;
	margin-right: 10px;
}

.conditions-additional-item.is-active .conditions-additional-item-check {
	background-color: #1277d4;
}

.conditions-additional-item-name {
	flex-grow: 1;
	color: #1a234c;
	font-size: 14px;
}

.conditions-item {
	display: flex;
	width: 100%;
	background-color: #f5f5f5;
	border-radius: 42px;
	padding: 20px 30px;
	margin-bottom: 20px;
	min-height: 84px;
	align-items: center;
}

.conditions-item:last-child {
	margin-bottom: 0px;
}

.conditions-item-text {
	flex-grow: 1;
}

.conditions-item-text-name {
	font-size: 24px;
	line-height: 28px;
	color: #1277d4;
}

.conditions-item-text-symptoms {
	font-size: 14px;
	line-height: 20px;
	color: #1a234c;
}

.conditions-item-text-symptoms span:first-child {
	font-weight: bold;
	text-transform: uppercase;
	margin-right: 10px;
}

.conditions-item-button {
	background-color: #1a234c;
	cursor: pointer;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	border-radius: 18px;
	font-weight: 300;
	white-space: nowrap;
	height: 36px;
	line-height: 36px;
	padding: 0px 20px;
	font-size: 12px;
	letter-spacing: 1.2px;
}

</style>
