<template>

<com-list api="links" :class="{'is-fancy': block.style === 1}" :block="block" v-on:loaded="onListLoaded">

	<div class="links" v-if="block.layout === $constants.linksLayout.list">

		<div class="links-item" v-for="(item, index) in list" :key="index">

			<router-link :to="$link(buildUrl(item))">{{ item.name }}</router-link>

		</div>

	</div>

	<div class="links is-cards" v-if="block.layout === $constants.linksLayout.cards">

		<router-link :to="$link(buildUrl(item))" class="links-card" v-for="(item, index) in list" :key="index">

			<div class="links-card-image" v-bgimage="item.image" />

			<div class="links-card-name">{{ item.name }}</div>

		</router-link>

	</div>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	},

	methods: {

		buildUrl: function(item) {

			var root = this.$route.path

			root = root.replace(this.$store.getters['language'].code.toLowerCase() + '/', '')
			root = root.replace(this.$store.getters['context'].url.toLowerCase() + '/', '')

			return (this.$_.contains([
				this.$constants.pageTypeIndex.product,
				this.$constants.pageTypeIndex.news,
				this.$constants.pageTypeIndex.event,
				this.$constants.pageTypeIndex.treatment,
				this.$constants.pageTypeIndex.symptom,
				this.$constants.pageTypeIndex.condition,
			], item.type)) ? root + '/' + item.url : '/' + item.url

		}

	}

}

</script>

<style scoped>

.links {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100%;
}

.is-mobile .links {
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .links.is-cards {
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.links-item {
	font-size: 16px;
	line-height: 20px;
	padding: 4px 0px;
	color: #1277d4;
}

.is-mobile .links-item {
	font-size: 14px;
}

.list.is-fancy .links-item {
	font-size: 12px!important;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 10px 50%;
    align-items: center;
    /* padding: 4px 0; */
    color: #1a234c;
    border: 2px solid #C21920;
    border-radius: 29px;
    height: 56px;
    padding: 0px 20px 0px 45px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAA7VBMVEUAAADWe4ytxZRin8zUb3tjmDykwY3Wcn65FSXCNUZGjcR0o1LWdX7ALjzWeISauoCGr2jSanXKT157rda4ECXNVmXMV2XRZXHQZHG4ESW/Kjx7qFrJSlfHR1dxp9Omw5AOarK8HjInerqBq2HFPk6VuHprnEa8IjHCNEZoos5rpdAbc7Y9h8FGjMNOkcWMsm/NWWVcmsuQtXWdvYQPa7J7plpFjcSHr2lVlsnNXWqYu4B3qtUygb7LTl7PXmvUbnsygL15plnPYGqLsm/NVmZUlsmQtXXNXGqiv4mfv4lzptOzAhgAYq1ZkDBakTFxDoIZAAAAS3RSTlMAHx6ccP5XV/7v2/ZX9jyc3IfPH/7Av5yc/vbv3NxXPP779ufnr/v7zIdx++fcz8/Ar8CH/u/b28CvnDzvsK5fvb2vo6OXl5VwcEUjKGKYAAAC+0lEQVRIx42W6XaiQBBGiw4KDIgCKu7GLY67MclM9sy+dcP7P85Ug6QhQPD+8XSdXKrqa5dABsqzrY0oMtLslgknoPRHNMHILvJaHZqirryryEdFa1iygk1ly+6EDhnmKJIdGH1LSlSD4SbeIXuZTqBkLYCO590MM5w2nytn5/IFWqVyKgGKNCAPssuwFJ6SDO9wLywxW1GyUC553gWJJdQJnWLrRhwbwimacBZf6A5OoIZrRcFrmLUY1W02m0Z0kvEQu2xMfvKadt2MlB6l8wo+xAiUOZ5UqjYj6QoHLEeNbAgx5qrLn+vOVQtgTzWLF21aiZrdeF4NEBM3Mo99KhWJ7Lrd3RA7uj26hz+fu917MNRo/L/Yim9lU9qHkD01yqXSZFLyq4BzurDz9VrX14lMXbEVDxDv1YIQ1SYlnQCQGrdc+OwfgvvpgjaFkJnn6eF0UVRUnvlkec22UEULnSp8YqtB1S9/i/5miPMRsETeFoVJF8brS/YBdijU0DlfrDbgz2RqivnKfKWGkGo6rB7hDC0+IToD5zqUZPHR+sUDt8R4VX+4RCOytgNnfP6A47kUjhw87zuX5Kig9uBCJ2cxC50BwSAqWux+v/LwJIhHjlaiF9F14uKD44jwkMpcunpjccfAEfIlU61Ib3sdiIHVlBSrGBmWmXCc5fKfCCLf2sf7LBj7AlMh5VhXEOeWsZ/QEJebbc0IxNkwtoSWeBtlWpeQZMXYgL9h25BvLRxIMGCM4UuHUivXEn3ESmt8uaN0CjnWwIH0dAvxcc+yaqIgAj+Pvlj6kGUNSao8Zmzz+l0pZ1g9SHGGjR4goE9pB1KYUqrkXDN2C8hxKxtOYI2NnNgPAG0VO5eMR/fKFC2lyDlDZwvJH6h2q9h5TFSUOp+waLaxA2nLlvIUZ80dTDvDGsk50lb0SSBpFNHkvLQ3DmTRqHNt9JzI0bQ1fnaWkIM5pQHtftOSEas5HfGjUvTfHk1Tb0AB0p2WVKYtCU7h5cfTx4Cn3y+QwX/Yx6AZPbAYNwAAAABJRU5ErkJggg==);
}

.links-item:hover {
	text-decoration: underline;
}

.links-card-image {
	width: 100%;
	height: 218px;
	border-radius: 13px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.is-mobile .links-card-image {
	height: 98px;
}

.links-card-name {
	font-size: 24px;
	color: #1a234c;
	line-height: 30px;
	padding: 10px 0px;
}

.is-mobile .links-card-name {
	font-size: 16px;
	line-height: 24px;
}

</style>
